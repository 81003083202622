(function() {
    window.services = {
        google(data) {
            return axios.post('/api/user/google', data);
        },
        signup(data) {
            return axios.post('/api/user/create', data);
        },
        login(data) {
            return axios.post('/api/user/authenticate', data);
        },
        delete(data) {
            return axios.post('/api/user/delete', data);
        },
        resetPassword(data) {
            return axios.post('/api/user/reset-password', data);
        },
        localities(data) {
            return axios.post('/api/location/locality', data);
        },
        attractions(data) {
            return axios.post('/api/location/attraction', data);
        },
        getLocation(name, lat, long) {
            return axios.get(`/api/location/get?name=${name}&lat=${lat}&long=${long}`);
        },
        saveLocation(data) {
            return axios.post('/api/location/save', data);
        },
        saveItinerary(data) {
            return axios.post('/api/plan/save', data);
        },
        deleteItinerary(data) {
            return axios.post('/api/plan/delete', data);
        },
        getItineraries() {
            return axios.get('/api/plan/get');
        },
        getPayments() {
            return axios.get('/api/payment/get');
        },
        getSubscriptionPlans() {
            return axios.get('/api/payment/plans');
        },
        submitPayment(data) {
            return axios.post('/api/payment/save', data);
        },
        savePurchase(data) {
            return axios.post('/api/payment/purchase', data);
        },
    }
})();