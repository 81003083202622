import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueSimpleAlert);

import './utils.js';
import './services/services.js';

import './components/map.js';
import './components/authentication.js';
import './components/payments.js';
import './components/settings.js';
import './components/resetPassword';

import './views/index.js';