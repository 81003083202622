if (!utils.hash('token') && !window.location.pathname === '/reset-password') {
    window.location.href = window.location.origin;
}

let template = `
    <section data-component="reset-password" class="small">
        <section class="header">
            <a href="/"><img src="images/logo-white-01.png"></a>
        </section>
        <section class="container">
            <h1>Reset Password</h1>
            <div class="form">
                <div>
                    <i class="left fa fa-lock fa-lg"></i>
                    <input @click="hideTooltip('password')" v-model="model.password1" v-on:keyup="clearErrors" v-on:keyup.enter="resetPassword" type="password" placeholder="Enter New Password" class="icon-left icon-right">
                    <i @click="toggleTooltip('password')" class="info right fa fa-info-circle fa-lg">
                        <div class="tooltip" v-bind:class="{ 'is-visible' : model.tooltips.password }">
                            <p>Password Requirements:</p>
                            <div>
                                <ul>
                                    <li>Minimum length of 6 characters</li>
                                    <li>At least one lowercase letter</li>
                                    <li>At least one uppercase letter</li>
                                    <li>At least one number</li>
                                </ul>
                            </div>
                        </div>
                    </i>
                </div>
                <div>
                    <i class="left fa fa-lock fa-lg"></i>
                    <input @click="hideTooltip('password')" v-model="model.password2" v-on:keyup="clearErrors" v-on:keyup.enter="resetPassword" type="password" placeholder="Re-enter New Password" class="icon-left icon-right">
                </div>
                <button @click="resetPassword" v-bind:class="{ error : model.buttons.reset.error }">{{ model.buttons.reset.text }}</button>
            </div>
        </section>
	</section>
`;

const RESET = 'Reset Password';
const INVALID_PASSWORD = 'Invalid Password';
const MISSMATCH_PASSWORD = 'Passwords Don\'t Match';

Vue.component('resetPassword', {
	template: template,
	data() {
		return {
			model: {
                buttons: {
                    reset: {
                        text: RESET,
                        error: false,
                    },
                },
                tooltips: {
                    password: false,
                },
                pasword1: null,
                password2: null,
			},
		}
	},
	mounted() {
		const c = this;
	},
	methods: {
        resetPassword() {
            const c = this;

            if (!utils.validatePassword(c.model.password1)) {
                c.model.buttons.reset.text = INVALID_PASSWORD;
                c.model.buttons.reset.error = true;

                return;
            }

            if (c.model.password1 !== c.model.password2) {
                c.model.buttons.reset.text = MISSMATCH_PASSWORD;
                c.model.buttons.reset.error = true;

                return;
            }

            c.clearErrors();

            services.resetPassword({
                token: utils.hash('token'), 
                password: c.model.password1
            })
            .then(function () {
                vm.$fire({
                    title: 'Password Reset',
                    text: "Password was successfully reset.",
                }).then((result) => {
                    history.replaceState(null, null, location.href);
                    window.location.href = window.location.origin;
                });
            })
            .catch(function () {

                c.model.buttons.reset.text = INVALID_PASSWORD;
                c.model.buttons.reset.error = true;

            });
        },
        toggleTooltip(key) {
			const c = this;

            c.model.tooltips[key] = !c.model.tooltips[key];
        },
        hideTooltip(key) {
			const c = this;

            c.model.tooltips[key] = false;
        },
        clearErrors() {
			const c = this;

            c.model.buttons.reset.text = RESET;
            c.model.buttons.reset.error = false;
        }
  	}
});