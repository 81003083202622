window.vm = new Vue({
  el: '[data-view="index"]',
  data() {
    return {
      
    }
  },
  mounted() {
    
    window.checkPremium();

  }
});
