window.authenticate = function(page) {
    vm.$refs.authentication.open(page);
}

if (utils.hash('access_token')) {
    services.google({
        'token': utils.hash('access_token')
    })
    .then(function () {
        window.location.href = window.location.origin;
    })
    .catch(function () {
        vm.$fire({
            title: 'Login Failed',
            text: "Sorry, something went wrong while trying to login to your google account.",
            type: 'warning',
        }).then((result) => {
            window.location.href = window.location.origin;
        });
    });
}

let template = `
    <section data-component="authentication" class="small" v-bind:class="{ 'is-visible' : model.settings.open }">
        <section class="background" @click="close"></section>
        <section class="header">
            <img src="images/logo-white-01.png">
        </section>
        <section class="container" v-bind:class="{ 'is-visible' : model.settings.visible }">
            <button @click="close" class="close"><i class="fa fa-times fa-lg"></i></button>
            <h1 v-if="model.settings.page == 'login'">Log In</h1>
            <h1 v-if="model.settings.page == 'signup'">Sign Up</h1>
            <div class="form">
                <div>
                    <i class="left fa fa-user fa-lg"></i>
                    <input @click="hideTooltip('password')" v-model="model.user.email" v-on:keyup="clearErrors" v-on:keyup.enter="submit" type="email" placeholder="Email Address" class="icon-left">
                </div>
                <div>
                    <i class="left fa fa-lock fa-lg"></i>
                    <input @click="hideTooltip('password')" v-model="model.user.password" v-on:keyup="clearErrors" type="password" v-on:keyup.enter="submit"  placeholder="Password" class="icon-left icon-right">
                    <i @click="toggleTooltip('password')" class="info right fa fa-info-circle fa-lg">
                        <div class="tooltip" v-bind:class="{ 'is-visible' : model.tooltips.password }">
                            <p>Password Requirements:</p>
                            <div>
                                <ul>
                                    <li>Minimum length of 6 characters</li>
                                    <li>At least one lowercase letter</li>
                                    <li>At least one uppercase letter</li>
                                    <li>At least one number</li>
                                </ul>
                            </div>
                        </div>
                    </i>
                </div>
                <button v-if="model.settings.page == 'login'" @click="login" v-bind:class="{ error : model.buttons.login.error }">{{ model.buttons.login.text }}</button>
                <button v-if="model.settings.page == 'signup'" @click="signup" v-bind:class="{ error : model.buttons.signup.error }">{{ model.buttons.signup.text }}</button>
            </div>
            <div class="options">
                <span v-if="model.settings.page == 'signup'"></span>
                <button v-if="model.settings.page == 'login'" @click="resetPassword">Forgot Password?</button>
                <button v-if="model.settings.page == 'signup'" @click="togglePage('login')">Have an account? Log In</button>
                <button v-if="model.settings.page == 'login'" @click="togglePage('signup')">New User? Sign Up</button>
            </div>
            <div class="other">
                Or Login Using: <button @click="googleAuth"><i class="fa fa-google fa-lg"></i></button>
            </div>
            <div class="policy-links">
                <a href="/privacy-policy" target="_blank">Privacy Policy</a>
                <a href="/terms-and-conditions" target="_blank">Terms & Conditions</a>
            </div>
        </section>
	</section>
`;

const LOGIN = 'Log In';
const SIGNUP = 'Sign Up';
const INVALID_EMAIL = 'Invalid Email Address';
const INVALID_PASSWORD = 'Invalid Password';
const PASSWORD_REQUIREMENTS = 'Invalid Password';
const INVALID_LOGIN = 'Invalid Email Or Password';
const ENTER_EMAIL = 'Enter Your Email';

Vue.component('authentication', {
	template: template,
	data() {
		return {
			model: {
                settings: {
                    page: 'login',
                    open: false,
                    visible: true,
                },
                buttons: {
                    login: {
                        text: LOGIN,
                        error: false,
                    },
                    signup: {
                        text: SIGNUP,
                        error: false,
                    },
                },
                tooltips: {
                    password: false,
                },
                user: {
                    email: null,
                    password: null,
                }
			},
		}
	},
	mounted() {
		const c = this;
	},
	methods: {
        open(page) {
            const c = this;

            c.model.settings.open = true;
            c.model.settings.page = page;
        },
        close() {
            const c = this;

            c.model.settings.open = false;
            c.hideTooltip('password');

            window.checkPremium();
        },
        submit() {
            const c = this;

            if (c.model.settings.page == 'login') {
                c.login();
            } else {
                c.signup();
            }
        },
        login() {
            const c = this;

            services.login({
                email: c.model.user.email, 
                password: c.model.user.password
            })
            .then(function () {
                c.close();
            })
            .catch(function () {

                c.model.buttons.login.text = INVALID_LOGIN;
                c.model.buttons.login.error = true;

            });
        },
        signup() {
            const c = this;

            if (!utils.validateEmail(c.model.user.email)) {
                c.model.buttons.signup.text = INVALID_EMAIL;
                c.model.buttons.signup.error = true;

                return;
            }

            if (!utils.validatePassword(c.model.user.password)) {

                c.model.buttons.signup.text = INVALID_PASSWORD;
                c.model.buttons.signup.error = true;

                c.toggleTooltip('password');

                return;
            }

            services.signup({
                email: c.model.user.email, 
                password: c.model.user.password
            })
            .then(function () {
                c.close();
            })
            .catch(function (error) {

                let response = error.response.data;

                if (response.error) {
                    c.model.buttons.signup.text = error.response.data.error;
                } else if (response.key === 'email') {
                    c.model.buttons.signup.text = INVALID_EMAIL;
                } else if (response.key === 'password') {
                    c.model.buttons.signup.text = INVALID_PASSWORD;
                }

                c.model.buttons.signup.error = true;
                
            });
        },
        googleAuth() {
            let clientId = '904371669783-sttjto55se8voveibfdj2tr7e0gunhmf.apps.googleusercontent.com';
            let redirectUrl = 'https://excursion.nz/';

            let scopes = [
                'openid',
                'https://www.googleapis.com/auth/userinfo.profile',
                'https://www.googleapis.com/auth/userinfo.email',
              ]
            
            scopes = scopes.join(' ')

            window.location.href = `https://accounts.google.com/o/oauth2/auth?scope=${scopes}&include_granted_scopes=true&response_type=token&state=state_parameter_passthrough_value&redirect_uri=${redirectUrl}&client_id=${clientId}`;
        },
        resetPassword() {
            const c = this;

            if (!c.model.user.email) {
                c.model.buttons.login.text = ENTER_EMAIL;
                c.model.buttons.login.error = true;
            }

            vm.$fire({
                title: 'Reset Password?',
                text: "We will send you an email with a link to reset your password.",
                type: 'info',
                showCancelButton: true,
                confirmButtonText: 'Reset'
            }).then((result) => {
                if (result.value) {

                    services.resetPassword({
                        email: c.model.user.email
                    })
                    .then(function () {

                        vm.$fire({
                            title: 'Reset Password',
                            text: "We have sent an email to your inbox with a reset passoword link.",
                            type: 'info',
                        });

                    })
                    .catch(function () {

                        vm.$fire({
                            title: 'Sorry, something went wrong.',
                            text: '',
                            type: 'error',
                        });

                    });

                }
            });
        },
        togglePage(page) {
			const c = this;

            c.model.settings.visible = false;

            c.model.settings.page = page;

            setTimeout(function() {
                c.model.settings.visible = true;
            }, 200)
        },
        toggleTooltip(key) {
			const c = this;

            c.model.tooltips[key] = !c.model.tooltips[key];
        },
        hideTooltip(key) {
			const c = this;

            c.model.tooltips[key] = false;
        },
        clearErrors() {
			const c = this;

            c.model.buttons.login.text = LOGIN;
            c.model.buttons.login.error = false;
            
            c.model.buttons.signup.text = SIGNUP;
            c.model.buttons.signup.error = false;
        }
  	}
});