window.payments = function() {
    vm.$refs.payments.open();
}

let template = `
    <section data-component="payments" v-bind:class="{ 'is-visible' : model.open, 'small' : model.page == 'payment' }">
        <section class="background" @click="close"></section>
        <section class="container" v-bind:class="{ 'is-visible' : model.visible }">
            <button @click="close" class="close"><i class="fa fa-times fa-lg"></i></button>
            <div v-if="model.page == 'plans'" class="plans">
                <h1>Plans</h1>
                <section class="wrapper">
                    <div class="box" v-for="(plan, key) in model.plans">
                        <h3>{{plan.name}}</h3>
                        <h2>\${{plan.price}} USD</h2>
                        <ul>
                            <li v-for="i in plan.info"><i class="fa fa-check-circle fa-lg"></i> {{i}}</li>
                        </ul>
                        <button @click="subscribe(key)">Subscribe</button>
                    </div>
                </section>
            </div>
            <div v-if="model.page == 'payment'" class="payment">
                <h1>Payment</h1>
                <div class="form" v-on:keyup="removeError">
                    <div>
                        <label>Card Number</label>
                        <span class="input"><div id="card-number-element"></div></span>
                    </div>
                    <div class="double">
                        <div>
                            <label>Expiration Date</label>
                            <span class="input"><div id="card-expiry-element"></div></span>
                        </div>
                        <div>
                            <label>CVC</label>
                            <span class="input"><div id="card-cvc-element"></div></span>
                        </div>
                    </div>
                    <button @click="payWithStripe" v-bind:class="{ 'is-disabled' : model.submitting, error : model.stripe.failed }">{{ model.buttonText }}</button>
                </div>
            </div>
        </section>
	</section>
`;

const PROCESS = 'Process Payment';
const PROCESSING = 'Processing';
const FAILED = "Payment Failed";

Vue.component('payments', {
	template: template,
	data() {
		return {
			model: {
                page: 'plans',
                open: false,
                visible: true,
                submitting: false,
                buttonText: PROCESS,
                stripe: {
                    stripe: null,
                    publicKey: 'pk_live_360ATOn250DwPCxjxIVwHEFT',
                    elements: null,
                    cardNumberElement: null,
                    cardExpiryElement: null,
                    cardCvcElement: null,
                    succeeded: false,
                    failed: false,
                },
                plan: null,
                plans: window.plans,
			},
		}
	},
	mounted() {
		const c = this;

        c.getSubscriptionPlans();
	},
	methods: {
        getSubscriptionPlans() {
            const c = this;
            
            services.getSubscriptionPlans()
            .then(function (response) {
                
                for (let plan of response.data) {
                    c.model.plans[plan.ID].price = plan.price;
                }
    
            })
            .catch(function () {
    
            });
        },
        initStripe() {
            const c = this;

            // Initialize Stripe with your public key
            c.model.stripe.stripe = Stripe(c.model.stripe.publicKey);

            const style = {
                base: {
                    color: '#32325d',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '14px',
                    '::placeholder': {
                        color: '#aab7c4'
                    }
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a'
                }
            };

            // Initialize elements
            c.model.stripe.elements = c.model.stripe.stripe.elements();

            // Create an instance of the card number Element
            c.model.stripe.cardNumberElement = c.model.stripe.elements.create('cardNumber', {style: style});
            c.model.stripe.cardNumberElement.mount('#card-number-element');

            // Create an instance of the expiry Element
            c.model.stripe.cardExpiryElement = c.model.stripe.elements.create('cardExpiry', {style: style});
            c.model.stripe.cardExpiryElement.mount('#card-expiry-element');

            // Create an instance of the CVC Element
            c.model.stripe.cardCvcElement = c.model.stripe.elements.create('cardCvc', {style: style});
            c.model.stripe.cardCvcElement.mount('#card-cvc-element');
        },  
        async payWithStripe() {
            const c = this;

            c.model.submitting = true;
            c.model.buttonText = PROCESSING;

            let {token, error} = await c.model.stripe.stripe.createToken(c.model.stripe.cardNumberElement);
        
            if (token) {
                c.processPayment(token);
            } else {
                console.error(error);

                c.showError();
            }
        },
        processPayment(token) {
            const c = this;

            services.submitPayment({ token: token.id, planID: c.model.plan })
            .then(function (response) {
                if (!response.data.error) {
                    c.validatePayment(response.data.id);
                } else {
                    c.showError(response.data.error);
                }
            })
            .catch(function () {
                console.error('Payment Error:', error);
            });
        },
        validatePayment(id) {
            const c = this;

            services.getPayments()
            .then(function (response) {

                for (let payment of response.data) {

                    if (payment.id == id) {

                        if (payment.succeeded) {

                            c.model.buttonText = c.model.buttonText = `${PROCESS} - $${c.model.plans[c.model.plan].price}`;
                            c.model.stripe.succeeded = true;
                            c.model.submitting = false;

                            vm.$fire({
                                title: 'Payment Complete',
                                text: "Thanks for purchasing premium access. New features and limits have been unlocked",
                                type: 'success',
                            }).then((result) => {
                                window.location.reload();
                            });

                            // c.close();

                        } else if (payment.failed) {
                            c.showError();
                        } else {
                            c.validatePayment();
                        }

                    } else {
                        c.validatePayment(); 
                    }

                }

            });
        },
        showError(message = null) {
            const c = this;

            c.model.stripe.failed = true;
            c.model.buttonText = FAILED;

            if (message) {

                vm.$fire({
                    title: 'Sorry, payment failed.',
                    text: message,
                    type: 'error',
                });

            }

            c.removeError();
        },
        removeError() {
            const c = this;

            setTimeout(function() {

                if (c.model.stripe.failed) {

                    c.model.buttonText = c.model.buttonText = `${PROCESS} - $${c.model.plans[c.model.plan].price}`;;
                    c.model.stripe.succeeded = false;
                    c.model.stripe.failed = false;
                    c.model.submitting = false;

                }

            }, 3000);
        },
        open() {
            this.model.open = true;
        },
        close() {
            this.model.page = 'plans';
            this.model.open = false;
        },
        subscribe(id) {
            const c = this;

            c.model.plan = id;

            c.model.buttonText = `${PROCESS} - $${c.model.plans[c.model.plan].price}`;
            
            c.togglePage('payment', c.initStripe);
        },  
        togglePage(page, callback = null) {
			const c = this;

            c.model.visible = false;

            c.model.page = page;

            setTimeout(function() {
                c.model.visible = true;

                if (callback) {
                    callback();
                }
            }, 200)
        }
  	}
});