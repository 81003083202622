let template = `
    <section data-component="settings">
        <section v-if="!model.loggedIn" class="authentication">
            <div>
                <button class="white" @click="login">Log In</button>
            </div>
            <div>
                <button @click="signup">Sign Up</button>
            </div>
        </section>
        <section v-if="model.loggedIn" class="settings">
            <div>
                <button v-if="!model.hasPremium" class="green" @click="payments">Get Premium</button>
                <button v-else class="white" @click="logout">Log Out</button>
            </div>
            <div>
                <button @click="open">Settings</button>
            </div>
        </section>
        <section class="popup small" v-bind:class="{ 'is-visible' : model.open }">
            <section class="background" @click="close"></section>
            <section v-if="!model.viewPayments" class="container" v-bind:class="{ 'is-visible' : model.visible }">
                <button @click="close" class="close"><i class="fa fa-times fa-lg"></i></button>
                <h1>Settings</h1>
                <div class="form box">
                    <div class="setting">
                        <p>Log Out</p>
                        <button @click="logout">Log Out</button>
                    </div>
                    <div class="setting">
                        <p>Payments</p>
                        <button @click="viewPayments">View</button>
                    </div>
                    <div class="setting">
                        <p>Reset Password</p>
                        <button @click="resetPassword">Reset</button>
                    </div>
                    <div class="setting">
                        <p>Delete Account</p>
                        <button @click="deleteAccount">Delete</button>
                    </div>
                </div>
            </section>
            <section v-if="model.viewPayments" class="container" v-bind:class="{ 'is-visible' : model.visible }">
                <button @click="close" class="close"><i class="fa fa-times fa-lg"></i></button>
                <h1>Payments</h1>
                <div class="form box">
                    <div v-if="!model.payments.length" class="setting">
                        <p>No Payment History</p>
                    </div>
                    <div v-else class="payments">
                        <ul>
                            <li class="payment keys">
                                <div>Plan</div>
                                <div>Price</div>
                                <div>Payment Date</div>
                                <div>Expiration Date</div>
                            </li>
                            <li v-for="payment in model.payments" v-if="payment.succeeded" class="payment">
                                <div>{{ payment.name }}</div>
                                <div>{{ payment.price }}</div>
                                <div>{{ payment.dateCreated }}</div>
                                <div v-bind:class="{ expired : payment.expired == true, active : payment.expired == false }">{{ payment.expireDate }}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </section>
	</section>
`;

Vue.component('settings', {
	template: template,
	data() {
		return {
			model: {
                open: false,
                visible: true,
                loggedIn: utils.cookies.has('token'),
                viewPayments: false,
                payments: [],
                hasPremium: window.hasPremium,
			},
		}
	},
	mounted() {
		const c = this;
	},
    created() {
        this.cookiePolling = setInterval(() => {
          this.model.hasPremium = window.hasPremium;
          this.model.loggedIn = utils.cookies.has('token');
        }, 1000);
    },
    beforeDestroy() {
      clearInterval(this.cookiePolling);
    },
    watch: {
        'model.loggedIn'(newVal, oldVal) {
            if (newVal !== oldVal) {
                clearInterval(this.cookiePolling);
            }
        },
    },
	methods: {
        login() {
            window.authenticate('login');
        },
        signup() {
            window.authenticate('signup');
        },
        logout() {
            utils.cookies.delete('token');
            utils.cookies.delete('hidl5dih');
            window.location.href = `${window.location.origin}/api/user/logout`;
        },
        getPremium() {
            window.payments();
        },
        viewPayments() {
            const c = this;

            c.close();

            services.getPayments()
            .then(function (response) {

                c.model.payments = response.data.reverse();

                for (let payment of c.model.payments) {
                    payment.name = window.plans[payment.pricingID].name;
                    payment.price = `$${payment.price}`;

                    payment.dateCreated = new Date(payment.dateCreated);
                    payment.expireDate = new Date(payment.expireDate);

                    payment.dateCreated = payment.dateCreated.toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                    });

                    payment.expireDate = payment.expireDate.toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                    });
                }
                
                c.open();
                c.model.viewPayments = true;
    
            });
        },
        resetPassword() {
            const c = this;

            services.resetPassword()
            .then(function () {

                vm.$fire({
                    title: 'Reset Password',
                    text: "We have sent an email to your inbox with a reset passoword link.",
                    type: 'info',
                });

            })
            .catch(function () {

                vm.$fire({
                    title: 'Sorry, something went wrong.',
                    text: '',
                    type: 'error',
                });

            });
        },
        deleteAccount() {
            const c = this;

            vm.$fire({
                title: 'Delete Account',
                text: "Are you sure you want to delete your account? This can't be undone.",
                type: 'warning',
                showCancelButton: true,
            }).then((result) => {
                if (result.value) {

                    services.delete()
                    .then(function () {
                        c.logout();
                    })
                    .catch(function () {

                        vm.$fire({
                            title: 'Sorry, something went wrong.',
                            text: '',
                            type: 'error',
                        });

                    });
                }
            });
        },
        open() {
            const c = this;

            c.model.open = true;
            c.model.viewPayments = false;
        },
        close() { 
            this.model.open = false;
        }
  	}
});