window.addToItinerary = function(name, lat, long) {
    let result = vm.$refs.maps.addToItinerary(name, lat, long);

    if (!result) {
        return;
    }

    // vm.$refs.maps.closeAllInfoWindows();

    let $button = document.querySelectorAll('button[button-remove="' + name + '"]')
    $button[0].classList.remove('is-hidden');

    $button = document.querySelectorAll('button[button-add="' + name + '"]')
    $button[0].classList.add('is-hidden');
}

window.removeFromItinerary = function(name, lat, long) {
    vm.$refs.maps.removeFromItinerary(name, lat, long);

    let $button = document.querySelectorAll('button[button-remove="' + name + '"]')
    $button[0].classList.add('is-hidden');

    $button = document.querySelectorAll('button[button-add="' + name + '"]')
    $button[0].classList.remove('is-hidden');
}

let template = `
    <section data-component="map" style="width: 100%; height: 100%;">
		<section class="header" v-bind:class="{ 'coming-soon': model.show.comingSoon }">
            <img src="images/logo-white-01.png">
        </section>
        <section class="container" v-bind:class="{ 'show-sidebar' : model.toggleSidebar }">
            <section class="sidebar" @click="closeAllInfoWindows">

            <settings ref="settings"></settings>
                <button class="toggle" @click="toggleSidebar" v-bind:class="{ flip : model.toggleSidebar }"><i class="fa fa-angle-double-left"></i></button>
                <section class="tabs" v-bind:class="{ triple : model.loggedIn }">
                    <div v-if="model.loggedIn" class="tab" @click="clickTab('plans')" v-bind:class="{ 'active' : model.tabs.plans }">Plans</div>
                    <div class="tab" @click="clickTab('create')" v-bind:class="{ 'active' : model.tabs.create }">Create</div>

                    <div v-if="!model.show.filters" class="tab is-disabled">Filters</div>
                    <div v-if="model.show.filters" class="tab" @click="clickTab('filters')" v-bind:class="{ 'active' : model.tabs.filters }">Filters</div>
                </section>
                <section class="inputs plans" v-bind:class="{ active : model.tabs.plans }">
                    <section class="logo">
                        <img src="images/logo-white-01.png">
                    </section>
                    
                    <h1>Your Plans</h1>
                    <h3>All your plans in one place</h3>
                    <p v-if="model.itineraries.length">Click on a plan to load them into the map or use the delete button to remove an unwanted plans.</p>
                    <p v-if="!model.itineraries.length">You have no plans. Click the create button below to start a new plan.</p>
                    <div>
                        <ul>
                            <li v-for="plan in model.itineraries">
                            <a @click="viewItinerary(plan)">{{plan.originName}} -> {{plan.destinationName}}</a>
                            <!--<button @click=""><i class="fa fa-pencil"></i></button>-->
                            <button @click="deleteItinerary(plan)"><i class="fa fa-trash"></i></button>
                        </ul>
                        <button v-if="!model.itineraries.length" @click="clickTab('create')">Create</button>
                    </div>
                </section>
                <section class="inputs" v-bind:class="{ active : model.tabs.create }">
                    <section class="logo">
                        <img src="images/logo-white-01.png">
                    </section>
                    
                    <h1 v-if="!model.show.create">Your Plan</h1>
                    <h1 v-if="model.show.create">Create a plan</h1>
                    <h3>Maximize your trip</h3>
                    <p v-if="model.show.create"><strong>Step 1:</strong> Find attractions and hidden gems along your route by entering your starting location and destination to create your plan.</p>
                    <div class="inputs" v-bind:class="{ bottom : model.results }">
                        <div>
                            <input v-if="model.show.create" v-model="model.origin.name" type="text" placeholder="Origin" v-on:keydown.enter="calculateDirections" v-bind:class="{ 'is-disabled' : model.waypoints.length }">	
                            <input v-if="model.show.create" v-model="model.destination.name" type="text" placeholder="Destination" v-on:keydown.enter="calculateDirections" v-bind:class="{ 'is-disabled' : model.waypoints.length }"/>
                            
                            <button v-if="!model.show.itinerary" @click="calculateDirections" v-bind:class="{ shrink : model.show.filters, 'is-disabled' : model.show.loading, error : model.buttons.create.error, 'is-disabled' : model.waypoints.length }">{{ model.buttons.create.text }}</button>
                            <button v-if="model.show.itinerary && model.show.create" @click="editDirections">Edit</button>
                            <button v-if="!model.show.itinerary" v-bind:class="{ 'is-visible' : model.show.filters }" class="reset" @click="resetAll"><i class="fa fa-undo fa-lg"></i></button>
                        </div>
                    </div>
                    
                    <section v-if="!model.show.itinerary" class="waypoints" v-bind:class="{ 'is-visible' : model.show.filters }">
                        <p><strong>Step 2:</strong> Want to customize your route? <br> Add some waypoints.</p>
                        <div v-bind:class="{ bottom : model.results }">
                            <div>
                                <input v-model="model.waypoint" type="text" v-on:keydown.enter="addWaypoint" placeholder="Enter Waypoint"/>
                                
                                <div class="waypoints" v-if="model.waypoints.length">
                                    <ul>
                                        <li v-for="(waypoint, index) in model.waypoints">
                                            {{ waypoint.name }} <button @click="deleteWaypoint(index)"><i class="fa fa-trash-o"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <button @click="addWaypoint" v-bind:class="{ shrink : model.waypoints.length, 'is-disabled' : waypointsRemaining == 0, error : model.buttons.waypoints.error }">{{ model.buttons.waypoints.text }}</button>
                                <button v-bind:class="{ 'is-visible' : model.waypoints.length }" class="reset" @click="resetWaypoints"><i class="fa fa-undo fa-lg"></i></button>
                            </div>
                        </div>
                    </section>
                    <section v-if="!model.show.itinerary" class="waypoints" v-bind:class="{ 'is-visible' : model.show.filters }">
                        <p><strong>Step 3:</strong> Click on the pins to view more about the location and add it to your itinerary.</p>
                        <p v-if="!model.hasPremium && model.loggedIn" class="info"><i class="fa fa-info-circle"></i> Get premium to view all locations.</p>
                        <div>
                            <div>
                                <button v-bind:class="{ 'is-disabled' : !model.itinerary.length }" @click="createItinerary">Create itinerary</button>
                            </div>
                        </div>
                        <p><strong>Location Limit:</strong> {{ model.limits.waypoints }} <br> <strong>Location Remaining:</strong> {{ waypointsRemaining }}</p>
                        
                        <p v-if="!model.loggedIn" class="info"><i class="fa fa-info-circle"></i> Log in to increase your limits.</p>
                    </section>

                    <section v-if="model.show.itinerary" class="waypoints is-visible">
                        <p class="distance"><strong>Distance:</strong> {{model.fullItinerary.distance}}</p>
                        <p class="duration"><strong>Duration:</strong> {{model.fullItinerary.duration}}</p>
                        <div class="waypoints">
                            <ul>
                                <li v-for="(waypoint, index) in model.fullItinerary.waypoints" class="route">
                                    <span class="icon" v-html="waypoint.icon"></span> 
                                    <span :title="waypoint.name">{{ waypoint.name }}</span> 
                                </li>
                            </ul>
                        </div>
                        <button v-if="model.show.create && model.loggedIn" @click="saveItinerary" v-bind:class="{ 'is-disabled' : hitSaveLimit }">Save</button>
                        <button v-if="model.show.create && !model.loggedIn" @click="window.authenticate('login')">Log In To Save</button>
                        <p v-if="hitSaveLimit && model.hasPremium" class="info"><i class="fa fa-info-circle"></i> You have hit your save limit of {{model.limits.saves}}. Delete a plan to be able to save a new one.</p>
                        <p v-if="hitSaveLimit && !model.hasPremium" class="info"><i class="fa fa-info-circle"></i> Get premium to enabled saving plans.</p>
                    </section>
                </section>
                <section class="filters" v-bind:class="{ 'active' : model.tabs.filters && !model.show.itinerary }">
                    <div class="filters-wrapper">
                        <div class="types">
                            <!--<h3>Types: {{ model.markers.length }}</h3>-->
                            <ul>
                                <li v-for="type in model.filters.types" @click="updateType(type)">
                                    <span class="icon" v-html="type.icon"></span>
                                    <span class="switch-label" v-bind:title="capitalize(type.label)"> {{ type.label }} </span>
                                    <span class="pin-count" title="Pins on the map">{{ type.count }}</span>
                                    <label class="switch">
                                        <input type="checkbox" v-bind:checked="type.selected">
                                        <span class="slider round"></span>
                                    </label>
                                </li>
                            </ul>
                            <button class="full" @click="resetFilter">Reset Filter</button>
                        </div>
                        <!--
                        <div class="ratings">
                            <h3>Ratings:</h3>
                            <ul>
                                <li @click="updateRating(2)">
                                    2 Stars & up
                                    <label class="radio">
                                        <input type="radio" v-model="model.filters.rating" value="2">
                                        <span class="circle round" v-bind:class="{ 'checked' : model.filters.rating == 2 }"></span>
                                    </label>
                                </li>
                                <li @click="updateRating(3)">
                                    3 Stars & up
                                    <label class="radio">
                                        <input type="radio" v-model="model.filters.rating" value="3">
                                        <span class="circle round" v-bind:class="{ 'checked' : model.filters.rating == 3 }"></span>
                                    </label>
                                </li>
                                <li @click="updateRating(4)">
                                    4 Stars & up
                                    <label class="radio">
                                        <input type="radio" v-model="model.filters.rating" value="4">
                                        <span class="circle round" v-bind:class="{ 'checked' : model.filters.rating == 4 }"></span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        -->
                    </div>
                </section>
            </section>
            <section class="map">
                <section class="loading" v-bind:class="{ 'is-visible' : model.show.loading }">
                    <div></div>
                </section>
                <div id="map" style="width: 100%; height: 80%;"></div>
                <div v-if="model.show.directions" id="map2" style="width: 100%; height: 80%;"></div>
                <section class="policy-links">
                    <a href="/privacy-policy">Privacy Policy</a>
                    <a href="/terms-and-conditions">Terms & Conditions</a>
                    <a href="mailto:support@excursion.nz">Support: support@excursion.nz</a>
                </section>
            </section>
        </section>
        <!--
        <section class="results">
            <div class="attractions">
                <ul>
                    <li v-for="attraction in model.attractions" v-if="attraction.visible">
                        {{ attraction.name }}: {{ attraction.vicinity }} - {{ attraction.rating }}
                    </li>
                </ul>
            <div>
        </section>
        -->
	</section>
`;

//yurkie07@gmail.com info
const API_KEY = 'AIzaSyAFJrVVO0wzq8VU3sqYxrpCOszTLo17fys';
const MAP_ID = 'd9b291376db5fc4';

const mapConfig = {
    mapId: MAP_ID,
    mapTypeControl: false, // Remove map and satellite buttons
    streetViewControl: false, // Remove street view buttons   
    fullscreenControl: false, // REmove fullscreen button
    styles: [
        {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [
                { visibility: "off" }
            ]
        },
        {
            featureType: "administrative.province",
            elementType: "labels.text.fill",
            stylers: [
                { visibility: "on" }
            ]
        },
        {
            featureType: "administrative.locality",
            elementType: "labels.text.fill",
            stylers: [
                { visibility: "on" }
            ]
        },
        {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [
                { visibility: "off" }
            ]
        },
        {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [
                { visibility: "off" }
            ]
        },
        {
            featureType: "transit",
            elementType: "labels.text.fill",
            stylers: [
                { visibility: "off" }
            ]
        },
        {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
                { visibility: "off" }
            ]
        }
    ]
}

//dym.development@gmail.com info
// const API_KEY = 'AIzaSyBB-upqB3kKzce_hvexgjxFrWO26dKLcMY';
// const MAP_ID = 'e55bb72268d43640';

const TYPES1 = {
    'origin': {
        backgroundColor: '#e02d09',
        borderColor: '#a32107',
        color: '#fff',
        icon: 'A',
    },
    'destination': {
        backgroundColor: '#e02d09',
        borderColor: '#a32107',
        color: '#fff',
        icon: 'B',
    },
    'waypoint': {
        backgroundColor: '#e02d09',
        borderColor: '#a32107',
        color: '#fff',
        icon: 'W',
    },
    'casino': {
        backgroundColor: '#FFD514',
        borderColor: '#ff8300',
        icon: 'money',
    },
    'park': {
        backgroundColor: '#14cc0e',
        borderColor: '#1b8517',
        icon: 'tree',
        label: 'park/reserve'
    },
    'zoo': {
        backgroundColor: '#f1a053',
        borderColor: '#a5550a',
        icon: 'paw',
    },
    'museum': {
        backgroundColor: '#e6f07a',
        borderColor: '#f0c61f',
        icon: 'institution',
    },
    'art gallery': {
        backgroundColor: '#eb81d7',
        borderColor: '#a13f8f',
        icon: 'paint-brush',
    },
    // 'point of interest': {
    //     backgroundColor: '#74bee8',
    //     borderColor: '#387fa8',
    //     icon: 'binoculars',
    // },
    'amusement park': { 
        backgroundColor: '#f06ca5',
        borderColor: '#b3326a',
        icon: 'rocket',
    },
    'movie theater': {
        backgroundColor: '#b077ed',
        borderColor: '#703ba8',
        icon: 'video-camera',
    },
    // 'bar': {
    //     backgroundColor: '#edcb80',
    //     borderColor: '#b09456',
    //     icon: 'beer',
    // },
    'campground': {
        backgroundColor: '#87e06c',
        borderColor: '#4f943a',
        icon: 'fire',
    },
    // 'food': {
    //     backgroundColor: '#8ff2db',
    //     borderColor: '#57ab97',
    //     icon: 'cutlery',
    // },
    'aquarium': {
        backgroundColor: '#6de5f2',
        borderColor: '#42a7b3',
        icon: 'anchor',
    },
    // 'lodging': {
    //     backgroundColor: '#b472f2',
    //     borderColor: '#773dad',
    //     icon: 'bed',
    // },
    // 'restaurant': {
    //     backgroundColor: '#8ff2db',
    //     borderColor: '#57ab97',
    //     icon: 'cutlery',
    // },
    // 'cafe': {
    //     backgroundColor: '#8ff2db',
    //     borderColor: '#57ab97',
    //     icon: 'cutlery',
    // },
    'beach': {
        backgroundColor: '#f7d599',
        borderColor: '#f7a81b',
        icon: 'life-bouy',
    },
    'other': {
        backgroundColor: '#f54242',
        borderColor: '#ad1717',
        icon: 'asterisk',
    },
    'waterfall': {
        backgroundColor: '#64c9f5',
        borderColor: '#2d81a6',
        icon: 'tint',
    }
}

const TYPES2 = {
    'bay': TYPES1.beach,
    'reef': TYPES1.beach,
    // 'valley': TYPES1.park,
    'forest': TYPES1.park,
    // 'wetland': TYPES1.park,
    'spring': TYPES1.waterfall,
    'lake': TYPES1.waterfall,
    'track': TYPES1.park,
    'island': TYPES1.beach,
    'glacier': TYPES1.park,
    'canyon': TYPES1.park,
    'cave': TYPES1.park,
    'peninsula': TYPES1.beach,
    'coast feature': TYPES1.beach,
    'pool': TYPES1.beach,
    'historic site': TYPES1.museum,
    'bush': TYPES1.park,
    'port': TYPES1.beach,
    // 'national park': TYPES1.park,
    // 'conservation park': TYPES1.park,
    // 'recreation reserve': TYPES1.park,
    // 'scenic reserve': TYPES1.park,
    // 'government purpose reserve': TYPES1.park,
    // 'scientific reserve': TYPES1.park,
    // 'historic reserve': TYPES1.park,
    // 'sanctuary area': TYPES1.park,
    // 'marine reserve': TYPES1.beach,
    'mud volcano': TYPES1.park,
    'recreation': TYPES1.park,
    'rapid': TYPES1.waterfall,
    // 'nature reserve': TYPES1.park,
    // 'ecological area': TYPES1.park,
    'cape': TYPES1.beach,
    'crater': TYPES1.park,
    'ice feature': TYPES1.park,
    'volcano': TYPES1.park,
    'marine feature': TYPES1.beach,
    // 'reserve (non-cpa)': TYPES1.park,
    'desert': TYPES1.park,
    'wilderness area': TYPES1.park,
    'sea': TYPES1.beach,
}

const TYPES = Object.assign({}, TYPES1, TYPES2);
const CREATE = 'Create';
const ADD_WAYPOINTS = 'Add Waypoint'
const MISSING_INPUT = 'Enter Origin and Destination';
const INVALID_LOCATIONS = 'Invalid Origin or Destination';
const INVALID_WAYPOINTS = 'Invalid Waypoint';
const DEFAULT_WAYPOINT_LIMIT = 3;
const DEFAULT_SAVE_LIMIT = 0;

Vue.component('maps', {
	template: template,
	data() {
		return {
			model: {
                loggedIn: utils.cookies.has('token'),
                hasPremium: window.hasPremium,
                tabs: {
                    plans: false,
                    create: true,
                    filters: false,
                },
				map: null,
				marker: null,
                defaults: {
                    lat: -41.27018558851394,
                    lng: 174.42716505862236,
                    zoom: 2,
                },
                origin: {
                    name: null,
                    lat: null,
                    long: null,
                },
      	        destination: {
                    name: null,
                    lat: null,
                    long: null,
                },
                buttons: {
                    create: {
                        text: CREATE,
                        error: false,
                    },
                    waypoints: {
                        text: ADD_WAYPOINTS,
                        error: false,
                    },
                },
                show: {
                    filters: false,
                    itinerary: false,
                    directions: false,
                    loading: false,
                    comingSoon: false,
                    create: true,
                },
                boundingBoxes: [],
				locations: [], //All locations found when creating a route
                attractions: [], //This is essentially the locations array with locations types filtered out that we don't want to show 
				waypoint: '',
				waypoints: [], //Manually added waypoints
                markers: [],
                filters: {
                    rating: 1,
                    types: [],
                },
                limits: {
                    waypoints: utils.cookies.get('hidl5dih') ? Number(utils.cookies.get('hidl5dih')) : DEFAULT_WAYPOINT_LIMIT,
                    saves: utils.cookies.get('5sdl3ih') ? Number(utils.cookies.get('5sdl3ih')) : DEFAULT_SAVE_LIMIT,
                },
                itinerary: [],
                fullItinerary: {
                    distance: null,
                    duration: null,
                    waypoints: [],
                },
                itineraries: [],
                infoWindow: null,
                toggleSidebar: true,
                results: false,
			},
		}
	},
	mounted() {
		const c = this;
            
        c.model.show.loading = true;

        // if (window.location.hash === '#test') {
        //     c.model.show.comingSoon = false;
        // }

		const callbackName = 'initMap';
		const script = document.createElement('script');

		script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=beta&callback=${callbackName}&libraries=places,geometry,marker`;
		script.async = true;
		script.defer = true;

		window[callbackName] = c.initMap;

		document.head.appendChild(script);
	},
    created() {
        this.cookiePolling = setInterval(() => {
          this.model.loggedIn = utils.cookies.has('token');
          this.model.hasPremium = window.hasPremium;
          this.model.limits.waypoints = utils.cookies.get('hidl5dih') ? Number(utils.cookies.get('hidl5dih')) : DEFAULT_WAYPOINT_LIMIT;
          this.model.limits.saves = utils.cookies.get('5sdl3ih') ? Number(utils.cookies.get('5sdl3ih')) : DEFAULT_SAVE_LIMIT;
        }, 1000);
    },
    beforeDestroy() {
      clearInterval(this.cookiePolling);
    },
    watch: {
        'model.filters.rating'() {
            this.filterAttractions();
        },
        'model.loggedIn'(newVal, oldVal) {
            if (newVal !== oldVal) {
                clearInterval(this.cookiePolling);
            }
        },
    },
    computed: {
        waypointsRemaining() {
            const c = this;

            return c.model.limits.waypoints - c.model.waypoints.length - c.model.itinerary.length;
        },
        hitSaveLimit() {
            const c = this;

            const limit = utils.cookies.get('5sdl3ih') ? Number(utils.cookies.get('5sdl3ih')) : DEFAULT_SAVE_LIMIT;

            return c.model.itineraries.length >= utils.cookies.get('5sdl3ih');
        }
    },
	methods: {
		initMap() {
			const c = this;

            let nzBounds = new google.maps.LatLngBounds(
                new google.maps.LatLng(-47.35, 166.1), // Southwest corner of New Zealand
                new google.maps.LatLng(-33.85, 179.15) // Northeast corner of New Zealand
            );

            let config = mapConfig;
            config.center = {lat: c.model.defaults.lat, lng: c.model.defaults.lng};
            config.zoom = c.model.defaults.zoom;
            config.restriction = {
                latLngBounds: nzBounds,
                strictBounds: true
            };

			c.model.map = new google.maps.Map(document.getElementById('map'), config);

			c.directionsService = new google.maps.DirectionsService();
			c.directionsRenderer = new google.maps.DirectionsRenderer();
			c.directionsRenderer.setMap(c.model.map);

            setTimeout(function() {
                c.model.show.loading = false;
            }, 1500);

            if (c.model.loggedIn) {
                c.getItineraries();
            }
		},
        clickTab(tab) {
			const c = this;

            c.model.tabs.plans = false;
            c.model.tabs.create = false;
            c.model.tabs.filters = false;

            c.model.tabs[tab] = true;

            if (tab == 'plans') {
                c.getItineraries();
            } else if (tab == 'create') {
                c.model.show.create = true;
                c.model.show.itinerary = false;

                c.reset();
            }
        },
		calculateDirections() {
			const c = this;

            c.clearErrors();

            if (!c.model.origin.name && !c.model.destination.name) {
                c.model.buttons.create.text = MISSING_INPUT;
                c.model.buttons.create.error = true;
                return;
            }

            c.model.show.itinerary = false;
            c.model.show.directions = false;
            c.model.results = true;
            c.model.show.loading = true;

            setTimeout(function() {

                c.reset(false);
                
                const waypoints = [];
                const boundsArray = [];

                for (let waypoint of c.model.waypoints) {
                    waypoints.push({
                        location: `${waypoint.name}, NZ`,
                        stopover: true
                    });
                }

                const request = { 
                    origin: `${c.model.origin.name}, NZ`,
                    destination: `${c.model.destination.name}, NZ`,
                    waypoints: waypoints,
                    travelMode: 'DRIVING',
                    region: 'NZ',
                };
        
                c.directionsService.route(request, (result, status) => {
                    if (status === 'OK') {

                        result.routes[0].legs.forEach(function(leg) {
                            // Get the bounding box for the leg
                            leg.steps.forEach(function(step) {
                                // Get the bounds for the step
                                let bounds = new google.maps.LatLngBounds();
                                bounds.extend(step.start_location);
                                bounds.extend(step.end_location);

                                if (!c.model.origin.lat) {
                                    c.model.origin.lat = step.start_location.lat();
                                    c.model.origin.long = step.start_location.lng();
                                }

                                c.model.destination.lat = step.end_location.lat();
                                c.model.destination.long = step.end_location.lng();

                                const distance = 5 * 1609.344; // 1 mile = 1609.344 meters
                                const sw = bounds.getSouthWest();
                                const ne = bounds.getNorthEast();
                                const swNew = google.maps.geometry.spherical.computeOffset(sw, distance, 225);
                                const neNew = google.maps.geometry.spherical.computeOffset(ne, distance, 45);
                                bounds.extend(swNew);
                                bounds.extend(neNew);

                                boundsArray.push(c.addBox(bounds.getSouthWest().lat(), bounds.getSouthWest().lng(), bounds.getNorthEast().lat(), bounds.getNorthEast().lng(), '#0362fc'));
                            });
                        });

                        c.directionsRenderer.setDirections(result);

                        c.search(boundsArray);
                    } else {

                        if (waypoints.length) {
                            c.model.buttons.waypoints.text = INVALID_WAYPOINTS;
                            c.model.buttons.waypoints.error = true;
                        } else {
                            c.model.buttons.create.text = INVALID_LOCATIONS;
                            c.model.buttons.create.error = true;
                        }

                        // c.reset();
                    }
                });

            }, 500);
		},
        editDirections() {
			const c = this;

            c.model.show.itinerary = false;
            c.model.show.directions = false;
            c.model.show.filters = true;
        },
        search(bounds) {
            const c = this;

            c.model.show.loading = true;

            services.localities({
                bounds: bounds, 
            })
            .then(function (response) {

                const coords = [];

                for (let location of response.data) {
                    coords.push({lat: location.lat, long: location.long});
                    c.model.locations.push(location.name);
                }

                setTimeout(function() {
                    c.getAttractions(coords);
                }, 1000);

            })
            .catch(function () {

                vm.$fire({
                    title: 'Sorry, something went wrong.',
                    text: '',
                    type: 'error',
                });

            });
        },
        addToItinerary(name, lat, long) {
            const c = this;

            if (!c.waypointsRemaining) {
                return false;
            }

            for (let attraction of c.model.attractions) {
                if (attraction.name === name && attraction.lat === lat && attraction.long === long && !attraction.added) {
                    attraction.added = true;
                    c.model.itinerary.push(attraction);

                    let elements = document.querySelectorAll('div[title="' + name + '"]');

                    elements.forEach(function(element) {
                        element.classList.add('added');
                    });

                    // console.log(c.model.itinerary);
                }
            }

            return true;
        },
        removeFromItinerary(name, lat, long) {
            const c = this;

            for (let i = 0; i < c.model.itinerary.length; i++) {
                let attraction = c.model.itinerary[i];
                if (attraction.name === name && attraction.lat === lat && attraction.long === long) {
                    attraction.added = false;
                    c.model.itinerary.splice(i, 1);

                    let elements = document.querySelectorAll('div[title="' + name + '"]');

                    elements.forEach(function(element) {
                        element.classList.remove('added');
                    });

                    // console.log(c.model.itinerary);
                }
            }
        },
        createItinerary() {
            const c = this;

            c.model.show.loading = true;
            c.model.show.filters = false;
            c.model.show.directions = true;

            const originPlace = new google.maps.LatLng(c.model.origin.lat, c.model.origin.long);
            const destinationPlace = new google.maps.LatLng(c.model.destination.lat, c.model.destination.long);
            const waypointsArray = [];

            for (let waypoint of c.model.waypoints) {
                console.log(waypoint.placeId);
                waypointsArray.push({ location: { placeId: waypoint.placeId }, stopover: true });
            }

            for (let attraction of c.model.itinerary) {
                console.log(attraction.placeId);
                waypointsArray.push({ location: { placeId: attraction.placeId }, stopover: true });
            }

            const directionsService = new google.maps.DirectionsService();

            const request = {
                origin: originPlace,
                destination: destinationPlace,
                waypoints: waypointsArray, // Array of LatLng objects or place IDs
                travelMode: google.maps.TravelMode.DRIVING, // Specify the desired travel mode
                optimizeWaypoints: true, //Optimized is limited to 10 with google maps
            };

            directionsService.route(request, (response, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    // console.log(response);

                    c.model.show.itinerary = true;
                    // Handle the successful response and process the directions data
                    const directions = response.routes[0].legs[0];

                    c.model.fullItinerary.distance = directions.distance.text;
                    c.model.fullItinerary.duration = directions.duration.text;

                    c.reorderItinerary(response.geocoded_waypoints)

                    let nzBounds = new google.maps.LatLngBounds(
                        new google.maps.LatLng(-47.35, 166.1), // Southwest corner of New Zealand
                        new google.maps.LatLng(-33.85, 179.15) // Northeast corner of New Zealand
                    );

                    let config = mapConfig;
                        config.zoom = c.model.defaults.zoom;
                        config.restriction = {
                            latLngBounds: nzBounds,
                            strictBounds: true
                    };

                    const map = new google.maps.Map(document.getElementById('map2'), config);
                      
                    const directionsRenderer = new google.maps.DirectionsRenderer({
                        map: map,
                        suppressMarkers: true, // Suppress default markers
                    });
                    
                    directionsRenderer.setDirections(response);

                    for (let attraction of c.model.itinerary) {

                        let type = attraction.type.toLowerCase();

                        const reserves = ['ice feature', 'bush', 'cave', 'recreation', 'glacier', 'desert', 'crater', 'track', 'forest', 'national park', 'conservation park', 'recreation reserve', 'scenic reserve', 'government purpose reserve', 'scientific reserve', 'historic reserve', 'sanctuary area', 'marine reserve', 'nature reserve', 'ecological area', 'reserve (non-cpa)'];

                        if (reserves.includes(type)) {
                            type = 'park';
                        }

                        c.addMarker({
                            placeId: attraction.placeId,
                            name: attraction.name,
                            vicinity: null,
                            rating: Number(5),
                            lat: attraction.lat,
                            long: attraction.long,
                            type: type,
                        }, map);

                    }

                    for (let waypoint of c.model.waypoints) {
                        c.addMarker({
                            placeId: waypoint.placeId,
                            name: waypoint.name,
                            vicinity: null,
                            rating: Number(5),
                            lat: waypoint.lat,
                            long: waypoint.long,
                            type: 'waypoint',
                        }, map);
                    }

                    c.addMarker({
                        placeId: null,
                        name: c.model.origin.name,
                        vicinity: null,
                        rating: Number(5),
                        lat: c.model.origin.lat,
                        long: c.model.origin.long,
                        type: 'origin',
                    }, map);

                    c.addMarker({
                        placeId: null,
                        name: c.model.destination.name,
                        vicinity: null,
                        rating: Number(5),
                        lat: c.model.destination.lat,
                        long: c.model.destination.long,
                        type: 'destination',
                    }, map);

                    // Center the map on the bounds of the directions response
                    const bounds = new google.maps.LatLngBounds();
                    const route = response.routes[0];
                    route.legs.forEach(leg => {
                        leg.steps.forEach(step => {
                            step.path.forEach(path => {
                                bounds.extend(path);
                            });
                        });
                    });

                    map.fitBounds(bounds);

                    c.model.show.loading = false;
                } else {
                    // Handle the error status if directions retrieval fails
                    console.error('Directions request failed. Status:', status);

                    let message = 'There was an issue with one of the locations added to your itinerary.';
                    message += ` It's most likely due to the location not being accessible by cars.`;
                    message += ` Double check your location and try again.`;
                    
                    vm.$fire({
                        title: 'Sorry, something went wrong.',
                        text: message,
                        type: 'error',
                    });

                    c.model.show.loading = false;
                    c.model.show.filters = true;
                    c.model.show.directions = false;
                }
            });
            
        },
        saveItinerary() {
            const c = this;

            let itinerary = [];
            let order = 1;

            for (let waypoint of c.model.fullItinerary.waypoints) {
                itinerary.push({
                    name: waypoint.name,
                    lat: waypoint.lat,
                    long: waypoint.long,
                    placeId: waypoint.placeId || '',
                    type: waypoint.type || '',
                    order: order,
                });
                order += 1;
            }

            services.saveItinerary({
                distance: c.model.fullItinerary.distance,
                duration: c.model.fullItinerary.duration,
                locations: itinerary, 
            })
            .then(function (response) {

                c.model.show.itinerary = true;
                c.model.show.create = false;

            })
            .catch(function (e) {

                vm.$fire({
                    title: 'Sorry, something went wrong.',
                    text: '',
                    type: 'error',
                });

            });
        },
        getItineraries() {
            const c = this;

            services.getItineraries()
            .then(function (response) {
                for (let location of response.data) {
                    location.originName = location.locations[0].name;
                    location.destinationName = location.locations[location.locations.length - 1].name;
                }

                c.model.itineraries = response.data;
            });
        },
        viewItinerary(plan) {
            const c = this;

            c.model.fullItinerary.distance = plan.distance;
            c.model.fullItinerary.duration = plan.duration;
            
            c.model.itinerary = [];
            c.model.waypoints = [];

            for (let location of plan.locations) {
                let type = location.type;

                if (type == 'origin') {
                    c.model.origin.name = location.name;
                    c.model.origin.lat = location.lat;
                    c.model.origin.long = location.long;
                } else if (type == 'destination') {
                    c.model.destination.name = location.name;
                    c.model.destination.lat = location.lat;
                    c.model.destination.long = location.long;
                } else if (type == 'waypoint') {
                    c.model.waypoints.push(location);
                } else {
                    c.model.itinerary.push(location);
                }

                if (type == 'origin' || type == 'destination' || type == 'waypoint') {
                    location.icon = '<i class="fa fa-map-marker fa-lg"></i>';
                } else {
                    location.icon = `<i class="fa fa-${TYPES[location.type].icon} fa-lg"></i>`;
                }
            }
            
            c.model.fullItinerary.waypoints = plan.locations;

            c.model.show.itinerary = true;
            c.model.show.create = false;
            
            c.createItinerary();
            
            c.model.tabs.plans = false;
            c.model.tabs.create = true;
        },
        deleteItinerary(plan) {
            const c = this;

            vm.$fire({
                title: 'Delete Plan?',
                text: "Are you sure you want to delete this plan? This can't be undone.",
                type: 'warning',
                showCancelButton: true,
            }).then((result) => {
                if (result.value) {
                    services.deleteItinerary({
                        id: plan.id
                    })
                    .then(function (response) {
                        for (let i = c.model.itineraries.length - 1; i >= 0; i--) {
                            if (c.model.itineraries[i].id == plan.id) {
                                c.model.itineraries.splice(i, 1);
                            }
                        } 
                    });
                }
            });
        },
        reorderItinerary(results) {
            const c = this;

            c.model.fullItinerary.waypoints = [];

            c.model.fullItinerary.waypoints.push({
                name: c.model.origin.name,
                icon: ('<i class="fa fa-map-marker fa-lg"></i>'),
                lat: c.model.origin.lat,
                long: c.model.origin.long,
                type: 'origin',
            });

            for (let result of results) {
                for (let waypoint of c.model.waypoints) {
                    if (result.place_id == waypoint.placeId) {
                        waypoint.icon = '<i class="fa fa-map-marker fa-lg"></i>';
                        c.model.fullItinerary.waypoints.push(waypoint);
                        break;
                    }
                }
    
                for (let attraction of c.model.itinerary) {
                    if (result.place_id == attraction.placeId) {
                        attraction.icon = `<i class="fa fa-${TYPES[attraction.type].icon} fa-lg"></i>`;
                        c.model.fullItinerary.waypoints.push(attraction);
                        break;
                    }
                }
            }

            c.model.fullItinerary.waypoints.push({
                name: c.model.destination.name,
                icon: ('<i class="fa fa-map-marker fa-lg"></i>'),
                lat: c.model.destination.lat,
                long: c.model.destination.long,
                type: 'destination',
            });
        },
        getAttractions(coordsArray) {
            const c = this;

            services.attractions({
                bounds: coordsArray, 
            })
            .then(function (response) {

                c.getExtraLocations(response.data);

                c.model.filters.types.sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                });

                c.filterAttractions();
                // c.checkMarkerVisiblity();

            })
            .catch(function () {

                vm.$fire({
                    title: 'Sorry, something went wrong.',
                    text: '',
                    type: 'error',
                });

            });

        },
        getExtraLocations(results) {
            const c = this;

            for (let location of results) {

                if (!c.model.markers.includes(location.name)) {

                    let type = location.type.toLowerCase();

                    const reserves = ['ice feature', 'bush', 'cave', 'recreation', 'glacier', 'desert', 'crater', 'track', 'forest', 'national park', 'conservation park', 'recreation reserve', 'scenic reserve', 'government purpose reserve', 'scientific reserve', 'historic reserve', 'sanctuary area', 'marine reserve', 'nature reserve', 'ecological area', 'reserve (non-cpa)'];

                    if (reserves.includes(type)) {
                        type = 'park';
                    }

                    c.addMarker({
                        id: location.id,
                        placeId: null,
                        name: location.name,
                        vicinity: null,
                        rating: Number(5),
                        lat: location.lat,
                        long: location.long,
                        type: type,
                    }, c.model.map);

                    c.model.markers.push(location.name);

                }

            }
        },
        addMarker(place, map) {
            const c = this;

            const type = place.type;

            const iconElement = document.createElement("div");

            if (TYPES[type]) {

                let icon = TYPES[type].icon;
                let backgroundColor = TYPES[type].backgroundColor;
                let borderColor = TYPES[type].borderColor;
                let glyphColor = TYPES[type].borderColor;
                let label = TYPES[type].label || type;

                iconElement.innerHTML = `<i class="fa fa-${icon} fa-lg"></i>`;

                if (type == 'origin' || type == 'destination' || type == 'waypoint') {
                    iconElement.innerHTML = `<span style="color: ${TYPES[type].color}">${icon}</span>`;
                }

                const faPinView = new google.maps.marker.PinView({
                    glyph: iconElement,
                    glyphColor: glyphColor,
                    background: backgroundColor,
                    borderColor: borderColor,
                });

                let marker = new google.maps.marker.AdvancedMarkerView({
                    position: { 
                        lat: place.lat, 
                        lng: place.long, 
                    },
                    map: map,
                    title: place.name,
                    content: faPinView.element,
                });

                let attraction = {
                    id: place.id,
                    placeId: place.place_id,
                    name: place.name,
                    vicinity: place.vicinity,
                    rating: Number(place.rating),
                    lat: place.lat,
                    long: place.long,
                    type: type,
                    visible: true,
                    marker: marker,
                    added: false
                };

                c.model.attractions.push(attraction);

                if (type !== 'origin' || type !== 'destination' || type !== 'waypoint') {
                    if (!c.model.filters.types.some(t => t.name === type)) {
                        c.model.filters.types.push({
                            name: type,
                            selected: true,
                            label: label,
                            count: 1,
                            icon: iconElement.innerHTML
                        });
                    } else {
                        for (let t of c.model.filters.types) {
                            if (t.name == type) {
                                t.count += 1;
                            }
                        }
                    }
                }

                // Add a click listener to the marker
                marker.addListener('click', function() {
                    if (!marker.disabled) {
                        c.closeAllInfoWindows();
                        c.getLocationInformation(place, function(result) {
                            attraction.placeId = result.place_id || result.placeId;
                            c.createPopup(marker, place, result, attraction);
                        });
                    }
                });

            }

        },
        getLocationInformation(place, callback = null) {
            let c = this;

            let name = place.name;

            services.getLocation(name, place.lat,place.long)
            .then(function (response) {

                if (callback) {
                    callback(response.data);
                } else {
                    return response.data;
                }

            })
            .catch(function () {
                c.getLocationFromGoogle(place, callback);
            });
        },
        getLocationFromGoogle(place, callback = null) {
            let name = `${place.name}, ${place.type}`;

            if (!name.includes(', NZ')) {
                name = `${name}, NZ`;
            }

            let request = {
                query: name,
            };
            
            if (place.lat) {
                request.location = new google.maps.LatLng(place.lat, place.long);
            }

            let service = new google.maps.places.PlacesService(document.createElement('div'));

            service.textSearch(request, function(results, status) {
                if (status === google.maps.places.PlacesServiceStatus.OK && results.length > 0) {
                    let placeId = results[0].place_id;

                    service.getDetails({ placeId: placeId }, function(place, status) {
                        if (status === google.maps.places.PlacesServiceStatus.OK) {

                            place.save = true;

                            if (callback) {
                                callback(place);
                            } else {
                                return place;
                            }

                        } else {
                            console.log('Place details request failed: ' + status);
                        }
                    });
                } else {
                    vm.$fire({
                        title: 'Sorry, something went wrong.',
                        text: 'Unable to find the waypoint you tried to add. Double check it and try again.',
                        type: 'error',
                    });
                }
            });
        },
        createPopup(marker, place, result, attraction) {
            const c = this;

            let image = '';
            let reviews = '';
            let rating = ''

            let hasImage = '';
            let hasReviews = '';

            let infoHTML = '';
            let reviewsHTML = '';

            let buttons = '';

            if (!c.model.show.itinerary) {
                if (c.waypointsRemaining > 0) {
                    buttons += `<button class="add-button ${attraction.added ? 'is-hidden' : ''}" button-add="${place.name}" onclick="addToItinerary('${attraction.name}', ${attraction.lat}, ${attraction.long})">Add To Itinerary</button>`;
                }

                buttons += `<button class="remove-button ${attraction.added ? '' : 'is-hidden'}" button-remove="${place.name}" onclick="removeFromItinerary('${attraction.name}', ${attraction.lat}, ${attraction.long})">Remove From Itinerary</button>`;
            }

            if (result.save) {

                if (result.photos && result.photos[0]) {
                    let photo = result.photos[0];
                    image = photo.getUrl();
    
                    let attributions = '';
                    if (photo.html_attributions) {
                        attributions = photo.html_attributions[0];
                    }
    
                    if (image) {
                        image = `
                            <div>
                                <img src="${image}" />
                                <p class="attributions">Photo by ${attributions}</p>
                            </div>
                        `;
                    }
    
                    hasImage = 'has-image';
                }
    
                if (result.rating) {
                    let star = 'star';
                    if (result.rating > 1) {
                        star = 'stars'
                    }
                    
                    rating = `<p>Rating: ${result.rating} ${star} (${result.user_ratings_total})<p>`;
                }
    
                if (result.reviews && result.reviews.length) {
                    reviewsHTML += '<div class="reviews">';
    
                    for (let review of result.reviews) {
                        let star = 'star';
                        if (result.rating > 1) {
                            star = 'stars'
                        }
    
                        reviewsHTML += `<div class="review">
                            <p>${review.author_name} - ${review.relative_time_description} - ${review.rating} ${star}</p>
                            <p>${review.text}</p>
                        </div>`;
                    }
                    
                    reviewsHTML += '</div>'
                    hasReviews = 'has-reviews';
                }
    
                infoHTML = `
                    <h2>${place.name}</h2>
                    <p>${result['adr_address']}</p>
                    ${rating}
                    ${image}
                `;
    
                if (result.save) {
    
                    services.saveLocation({
                        placeID: result.place_id, 
                        name: place.name,
                        lat: attraction.lat,
                        long: attraction.long,
                        infoHTML: infoHTML,
                        reviewsHTML: reviewsHTML,
                        rating: result.rating,
                        hasImage: hasImage.length ? 1 : 0,
                        hasReviews: hasReviews.length ? 1 : 0
                    });
    
                }

            } else {
                infoHTML = result.infoHTML;
                reviewsHTML = result.reviewsHTML;  

                hasImage = result.hasImage ? 'has-image' : '';
                hasReviews = result.hasReviews ? 'has-reviews' : '';
            }

            const contentString = `
                <div class="location-popup ${hasImage} ${hasReviews}">
                    <div>
                        ${buttons}
                        ${infoHTML}
                    </div>
                    ${reviewsHTML}
                </div>
            `;

            const infowindow = new google.maps.InfoWindow({
                content: contentString,
                ariaLabel: "Uluru",
            });

            c.model.infoWindow = infowindow;

            infowindow.open({
                anchor: marker,
                map: c.model.map,
            });

            google.maps.event.addListener(c.model.map, "click", function(event) {
                c.closeAllInfoWindows();
            });
            
        },
        closeAllInfoWindows() {
            const c = this;

            if (c.model.infoWindow) {
                c.model.infoWindow.close();
            }
        },
        filterAttractions(modify = true) {
            const c = this;

            c.model.show.filters = true;
            c.model.show.loading = false;
            
            if (!c.model.attractions[0].visible) {
                c.model.attractions = c.shuffleAttractions(c.model.attractions);
            }

            for (let attraction of c.model.attractions) {

                if (attraction.rating >= Number(c.model.filters.rating)) {
                    attraction.visible = true; 

                    for (let type of c.model.filters.types) {
                        if ((attraction.type === type.name || attraction.type === type.name) && !type.selected) {
                            attraction.visible = false;
                            c.hideMarker(attraction);
                        }
                    }

                    if (attraction.visible) {
                        c.showMarker(attraction);
                    }
                    
                    if (modify) {
                        c.modifyMarker(attraction);
                    }

                } else { 
                    attraction.visible = false;
                    c.hideMarker(attraction);
                }
            }

            c.model.attractions.sort((a, b) => a.rating - b.rating);
        },
        checkMarkerVisiblity() {
            const c = this;

            let marker = this.model.attractions[0].marker;
            let title = marker.title;
            
            this.model.show.loading = false;

            if (document.querySelectorAll('div[title="' + title + '"]').length) {
                c.filterAttractions();
            } else {
                setTimeout(function() {
                    c.checkMarkerVisiblity();
                }, 300);
            }
        },
        resetFilter() {
            const c = this;

            for (let type of c.model.filters.types) {
                type.selected = true;
            }

            c.filterAttractions();
        },
        shuffleAttractions(array) {
            const c = this;

            let currentIndex = array.length, temporaryValue, randomIndex;

            // While there remain elements to shuffle
            while (currentIndex !== 0) {
                // Pick a remaining element
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // Swap it with the current element
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }

            return array;
        },
        toggleSidebar() {
            const c = this;

            c.model.toggleSidebar = !c.model.toggleSidebar;
        },
        updateType(type) {
            const c = this;

            type.selected = !type.selected;

            c.filterAttractions(false);
        },
        updateRating(rating) {
            this.model.filters.rating = rating;
        },
		addWaypoint() {
			const c = this;

            if (!c.waypointsRemaining) {
                return;
            }

			if (c.model.waypoint) {

                let waypoint = {
                    placeId: null,
                    name: c.model.waypoint,
                    lat: null,
                    long: null,
                    type: 'waypoint'
                }

                c.getLocationFromGoogle(waypoint, function(result) {
                    waypoint.placeId = result.place_id;
                    
                    if (result.geometry && result.geometry.location) {
                        waypoint.lat = result.geometry.location.lat();
                        waypoint.long = result.geometry.location.lng();
                    }

                    c.model.waypoints.push(waypoint);

                    c.model.waypoint = '';

                    if (c.model.attractions.length) {
                        setTimeout(function() {
                            c.calculateDirections();
                        }, 300);
                    }
                });

			} else {

                if (c.model.attractions.length) {
                    setTimeout(function() {
                        c.calculateDirections();
                    }, 300);
                }

            }
		},
		deleteWaypoint(index) {
			const c = this;

			c.model.waypoints.splice(index, 1);
            
            c.clearErrors();

            // if (c.model.attractions.length) {
                setTimeout(function() {
                    c.calculateDirections();
                }, 300);
            // }
		},
        resetWaypoints() {
            const c = this;

            vm.$fire({
                title: 'Reset Waypoints?',
                text: "Are you sure you want to reset your waypoints?",
                type: 'warning',
                showCancelButton: true,
            }).then((result) => {
                if (result.value) {
                    c.model.waypoints = [];

                    c.clearErrors();

                    if (c.model.attractions.length) {
                        setTimeout(function() {
                            c.calculateDirections();
                        }, 300);
                    }
                }
            });
        },
        resetAll() {
			const c = this;

            vm.$fire({
                title: 'Start Over?',
                text: "Are you sure you want to start over? This can't be undone.",
                type: 'warning',
                showCancelButton: true,
            }).then((result) => {
                if (result.value) {
                    c.reset();
                }
            });
        },
        reset(resetAll = true) {
			const c = this;

            for (let box of c.model.boundingBoxes) {
                box.setMap(null);
            }

            for (let attraction of c.model.attractions) {
                attraction.marker.setMap(null);
            }

            // Clear the array of bounding box references
            c.model.boundingBoxes = [];
            c.model.attractions = [];
            c.model.locations = [];
            c.model.markers = [];
            c.model.itinerary = [];

            c.model.toggleFilters = false;

            c.directionsRenderer.setDirections({ routes: [] });
            c.model.show.loading = false;
            
            if (resetAll) {
                let center = new google.maps.LatLng(c.model.defaults.lat, c.model.defaults.lng);
                c.model.map.setCenter(center);
                c.model.map.setZoom(c.model.defaults.zoom);
    
                c.model.results = false;
                c.model.show.filters = false;

                c.model.origin.name = '';
                c.model.origin.lat = null;
                c.model.origin.long = null;

                c.model.destination.name = '';
                c.model.destination.lat = null;
                c.model.destination.long = null;

                c.model.waypoints = [];
            }
        },
        clearErrors() {
			const c = this;

            c.model.buttons.create.text = CREATE;
            c.model.buttons.create.error = false;

            c.model.buttons.waypoints.text = ADD_WAYPOINTS;
            c.model.buttons.waypoints.error = false;
        },
        showMarker(attraction) {
			let title = attraction.marker.title;

            let elements = document.querySelectorAll('div[title="' + title + '"]');

            elements.forEach(function(element) {
                element.classList.remove('hide-marker');
            });
        },
        hideMarker(attraction) {
            let title = attraction.marker.title;

            let elements = document.querySelectorAll('div[title="' + title + '"]');

            elements.forEach(function(element) {
                element.classList.add('hide-marker');
            });
        },
        modifyMarker(attraction) {
            let title = attraction.marker.title;
            let disabled = attraction.name == attraction.id;

            attraction.disabled = disabled
            attraction.marker.disabled = disabled;

            let elements = document.querySelectorAll('div[title="' + title + '"]');

            elements.forEach(function(element) {
                if (disabled) {
                    element.classList.add('disabled-marker');
                    element.title = '';
                } else {
                    element.classList.add('enabled-marker');
                }
            });
        },
        addBox(south, west, north, east, color) {
            const c = this;

            let bounds = new google.maps.LatLngBounds(
                new google.maps.LatLng(south, west), // Southwest corner
                new google.maps.LatLng(north, east)  // Northeast corner
            );
            
            // Create a rectangle to represent the bounding box
            let rectangle = new google.maps.Rectangle({
                bounds: bounds,
                editable: true,
                draggable: true,
                strokeColor: color,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: color,
                fillOpacity: 0.35,
                map: c.model.map,
                visible: false,
            });

            c.model.map.fitBounds(bounds);

            c.model.boundingBoxes.push(rectangle);
            
            // Add an event listener to update the bounding box coordinates when the rectangle is moved or resized
            google.maps.event.addListener(rectangle, "bounds_changed", function() {
                bounds = rectangle.getBounds();
                // do something with the new bounds
            });
            

            return bounds;
        },
        capitalize(text) {
            const arr = text.split(' ');

            for (let i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }

            return arr.join(" ");
        },
  	}
});